<template>
  <div>
    <div class="content-header">
      <div class="title-1">
        <router-link to="/"
          ><img src="../assets/back_arrow.svg" /><span style="color: #44D62C;"
            >Home</span
          ></router-link
        >
      </div>
    </div>

    <div class="single-games">
      <div class="tabs">
        <form action="#" id="reglog">
          <input id="tab1" type="radio" name="tabs" checked />
          <label for="tab1">Login</label>
          <input id="tab2" type="radio" name="tabs" />
          <label for="tab2">Registration</label>
          <div class="content">
            <div id="content1">
              <div id="loginMessage" style="color:red;">&nbsp;</div>
              <input
                type="number"
                class="form-control"
                placeholder="Phone Number"
                v-model="userPhone"
              />
              <input
                type="password"
                class="form-control"
                placeholder="Password"
                v-model="userPassword"
              />
              <div class="forgot-pass">
                <router-link to="/forgot-password"
                  >Forgot Password?
                </router-link>
              </div>
              <button
                type="button"
                class="submit-btn1"
                @click="login"
                id="loginButton"
              >
                Login
              </button>
            </div>
            <div id="content2">
              <div id="errorMessage" style="color:red;">&nbsp;</div>
              <input
                type="text"
                class="form-control"
                v-model="name"
                placeholder="Name"
              />
              <input
                type="number"
                class="form-control"
                v-model="regristrationPhone"
                placeholder="Phone Number"
              />
              <input
                type="password"
                class="form-control"
                v-model="password"
                placeholder="Create Password"
              />
              <input
                type="password"
                class="form-control"
                v-model="confirmPassword"
                placeholder="Confirm Password"
              />
              <select v-model="operator">
                <option disabled value="">Please select operator</option>
                <option
                  v-for="data in paymentList"
                  :key="data.name"
                  :value="data.value"
                >
                  {{ data.name }}
                </option>
              </select>
              <button
                type="button"
                class="submit-btn1"
                id="userRegristration"
                @click="registration"
              >
                Registration
              </button>
            </div>
          </div>
        </form>
        <div class="no-internet" style="display: none">
          <div class="forgot-pass-1">
            <div class="otp-title">
              <h2>OTP</h2>
              <h3>Write down 4 digit code in below box</h3>
            </div>
            <div id="errorMessage2" style="color:red;">&nbsp;</div>
            <form action="#">
              <div class="content-forgot">
                <div id="content12">
                  <input
                    type="number"
                    v-model="otp"
                    class="form-control"
                    placeholder="4 digit code"
                  />
                </div>
              </div>
              <button
                type="button"
                class="submit-btn2"
                @click="passwordRecovery"
                id="resendOtp"
              >
                Resend Code
              </button>
            </form>
          </div>

          <button type="button" class="submit-btn1" @click="otpCheck" id="otp">
            Submit
          </button>
        </div>
      </div>
    </div>

    <footer>
      <div class="main-footer">
        <div class="social-icon"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Regristration",
  data: () => {
    return {
      name: "",
      phone: localStorage.getItem("phone"),
      password: "",
      confirmPassword: "",
      operator: "",
      message: "",
      userPhone: "",
      regristrationPhone: "",
      userPassword: "",
      isDisabled: 0,
      otp: "",
      paymentList: [],
      host: null
    };
  },
  created() {
    this.host = this.$root.url();
    if (localStorage.getItem("phone")) {
      this.$router.push({ name: "Home" });
    }
    const path = "operatorList";
    const url = `${this.host}/${path}`;
    axios
      .get(url)
      .then(response => {
        if (response.data.success === true) {
          this.paymentList = response.data.paymentList;
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    registration: function() {
      $("#userRegristration").hide();
      let name = this.name;
      let phone = this.regristrationPhone;
      let password = this.password;
      let confirmPassword = this.confirmPassword;
      let operator = this.operator;
      if (
        name !== "" &&
        phone !== "" &&
        password !== "" &&
        operator !== "" &&
        confirmPassword !== ""
      ) {
        if (password !== confirmPassword) {
          $("#errorMessage").html(
            "<p>password and confirm password must be same</p>"
          );
          $("#userRegristration").show();
          return false;
        }
        $("#errorMessage").html("<p>validation successful</p>");
        const path = "userRegistration";
        const url = `${this.host}/${path}?name=${name}&phone=${phone}&password=${password}&password_confirmation=${confirmPassword}&payment=${operator}`;
        console.log(url);
        axios
          .get(url)
          .then(function(response) {
            if (response.data.success === true) {
              $("#errorMessage").html(response.data.messages);
              localStorage.setItem("registerPhone", response.data.phone);
              localStorage.setItem("registerName", response.data.name);
              localStorage.setItem("registerPassword", response.data.password);
              localStorage.setItem("payment", response.data.payment);
              $("#userRegristration").show();
              $("#reglog").hide();
              $(".no-internet").show();
              return true;
            } else {
              $("#errorMessage").html(response.data.messages);
              $("#userRegristration").show();
              return true;
            }
          })
          .catch(() => {
            $("#errorMessage").html(
              "<p>something goes to wrong,please try again later</p>"
            );
            $("#userRegristration").show();
            return false;
          });
      } else {
        $("#errorMessage").html("<p>please fill up all fields</p>");
        $("#userRegristration").show();
        return false;
      }
    },
    login: function() {
      let vm = this;
      $("#loginButton").hide();
      let phone = this.userPhone;
      let password = this.userPassword;
      if (phone !== "" && password !== "") {
        $("#loginMessage").html("<p>validation successful</p>");
        const path = "userLogin";
        const url = `${this.host}/${path}?phone=${phone}&password=${password}`;
        axios
          .get(url)
          .then(function(response) {
            if (response.data.success === true) {
              $("#loginMessage").html(response.data.messages);
              localStorage.setItem("phone", response.data.phone);
              vm.$router.push({ name: "Home" });
              return true;
            } else {
              $("#loginButton").show();
              $("#loginMessage").html(response.data.messages);
              return true;
            }
          })
          .catch(() => {
            $("#loginButton").show();
            $("#loginMessage").html(
              "<p>something goes to wrong,please try again later</p>"
            );
            return false;
          });
      } else {
        $("#loginButton").show();
        $("#loginMessage").html("<p>please fill up all login fields</p>");
        return false;
      }
    },
    otpCheck: function() {
      let vm = this;
      $("#otp").hide();
      let otp = this.otp;
      let phone = localStorage.getItem("registerPhone");
      let name = localStorage.getItem("registerName");
      let password = localStorage.getItem("registerPassword");
      let payment = localStorage.getItem("payment");
      if (
        otp !== "" &&
        phone !== "" &&
        name !== "" &&
        password !== "" &&
        payment !== ""
      ) {
        $("#errorMessage2").html("<p>validation successful</p>");
        const path = "otp";
        const url = `${this.host}/${path}?name=${name}&phone=${phone}&password=${password}&otp=${otp}&payment=${payment}`;
        axios
          .get(url)
          .then(function(response) {
            if (response.data.success === true) {
              $("#otp").show();
              localStorage.clear();
              $("#errorMessage2").html(response.data.messages);
              $("#reglog").show();
              $(".no-internet").hide();
              localStorage.setItem("phone", response.data.phone);
              // localStorage.setItem("token", response.data.token);
              vm.$router.push({ name: "Home" });
            } else {
              $("#otp").show();
              $("#errorMessage2").html(response.data.messages);
              return true;
            }
          })
          .catch(() => {
            $("#otp").show();
            $("#errorMessage2").html(
              "<p>something goes to wrong,please try again later</p>"
            );
            return false;
          });
      } else {
        $("#otp").show();
        $("#errorMessage2").html("<p>please fill up OTP field</p>");
        return false;
      }
    },
    passwordRecovery: function() {
      $("#resendOtp").hide();
      let phone = localStorage.getItem("registerPhone");
      console.log("phone :", phone);
      if (phone !== "") {
        $("#errorMessage2").html("<p>&nbsp;</p>");
        const path = "forgotPassword";
        const type = "registration";
        const url = `${this.host}/${path}?phone=${phone}&type=${type}`;
        axios
          .get(url)
          .then(function(response) {
            if (response.data.success === true) {
              $("#resendOtp").show();
              $("#errorMessage2").html("successfully send otp");
              return true;
            } else {
              $("#resendOtp").show();
              $("#errorMessage2").html(response.data.messages);
              return true;
            }
          })
          .catch(() => {
            $("#resendOtp").show();
            $("#errorMessage2").html(
              "<p>something goes to wrong,please try again later</p>"
            );
            return false;
          });
      } else {
        $("#resendOtp").show();
        $("#errorMessage2").html("<p>please fill up phone number field</p>");
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
